<template>
  <div>
    <b-modal
      id="modal-edit-team"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('Edit')} ${editObject.teamName}`"
      hide-footer
      @close="onReset"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('TeamName')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="editObject.teamName"
            required
          />
        </b-form-group>

        <b-form-group :label="`${$t('TeamLeaderName')}:`">
          <vue-select
            v-model="teamLeadObj"
            :options="getFreeTeamLeaders"
            label="name"
            :placeholder="$t('SelectTeamLeader')"
          />
        </b-form-group>
        <b-form-group :label="`${$t('Role')}:`">
          <vue-select
            v-model="role"
            :options="filteredOptions"
            :clearable="false"
            label="text"
            :reduce="(e) => e.value"
            :placeholder="$t('SelectRole')"
          />
        </b-form-group>
        <b-form-group
          :label="role.label"
        >
          <vue-select
            v-model="editObject.users"
            :options="getAllFreeUsers"
            :multiple="true"
            label="name"
            :placeholder="$t('SelectEmployee')"
          />
        </b-form-group>
        <b-alert
          v-if="role != ''"
          variant="warning"
          show
        >
          {{ $t('KeepInMind') }} <br> {{ $t('ChangeRole') }}
        </b-alert>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onReset"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div></b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  // mixins: [validationMixin],

  props: {
    // eslint-disable-next-line vue/require-default-prop
    editObject: {
      type: Object,
    },
  },
  data() {
    return {
      role: '',
      teamLeadObj: { name: '', userId: '' },
      sewerOptions: [],
      showTeamLeaders: false,
      teamLeaders: {
        items: [],
      },
      form: {
        teamName: '',
        teamLeaderUserId: '',
        teamLeaderName: '',
        sewerIds: [],
        patternMakerIds: [],
        handSewerIds: [],
      },
      leaderName: '',
      filters: {
        page: 1,
        pageSize: 15,
        ...this.$route.query,
      },
      // getUserRole: [
      //   { value: 'Sewer', label: this.$t('Sewer') },
      //   { value: 'DiversPersonal', label: this.$t('DiversPersonal') },
      //   { value: 'Deliverer', label: this.$t('Deliverer') },
      //   { value: 'PatternMaker', label: this.$t('PatternMaker') },
      //   { value: 'Trainee', label: this.$t('Trainee') },
      //   { value: 'Designer', label: this.$t('Designer') },
      //   { value: 'OnlineSales', label: this.$t('OnlineSales') },
      //   { value: 'HandSewer', label: this.$t('HandSewer') },
      //   { value: 'SalesPerson', label: this.$t('SalesPerson') },
      //   { value: 'Fitter', label: this.$t('Fitter') },
      //   { value: 'StockManagerMaterialVariant', label: this.$t('StockManagerMaterialVariant') },
      //   { value: 'StockManagerWarehousClient', label: this.$t('StockManagerWarehousClient') },
      //   { value: 'StockManagerWarehousProductions', label: this.$t('StockManagerWarehousProductions') },
      //   { value: 'Receptionist', label: this.$t('Receptionist') },
      //   { value: 'Accountant', label: this.$t('Accountant') },
      //   { value: 'ExecutiveAssitant', label: this.$t('ExecutiveAssitant') },
      //   { value: 'CleaningPerson', label: this.$t('CleaningPerson') },
      //   { value: 'Chauffeur', label: this.$t('Chauffeur') },
      //   { value: 'Security', label: this.$t('Security') },

      // ],
    };
  },
  computed: {
    ...mapGetters(['getUserRole', 'getFreeTeamLeaders', 'getAllFreeUsers', 'getTeamOverview', 'getFreeUsers']),
    // roleOptions() {
    //   const excludedRoles = ['CEO', 'TeamLeader', 'Manager'];
    //   return this.getUserRole.filter((role) => { return !excludedRoles.includes(role) });
    // },
    filteredOptions() {
      const options = this.getUserRole.map((role) => {
        return {
          value: role,
          text: this.$t(role),
        }
      });
      return options;
    },
  },
  watch: {
    editObject() {
      this.form.teamName = this.editObject.teamName;
      this.teamLeadObj.name = this.editObject.teamLeaderName;
      this.teamLeadObj.userId = this.editObject.teamLeaderUserId;
    },
    role(value) {
      this.loadAllFreeUsersByRolePagination({ personRole: value })
    },
    teamLeadObj(value) {
      this.editObject.teamLeaderName = value.name
      this.editObject.teamLeaderUserId = value.userId
    },

  },
  mounted() {
    this.form = this.editObject;
    this.teamLeadObj.name = this.editObject.teamLeaderName;
    this.teamLeadObj.userId = this.editObject.teamLeaderUserId;
    // this.chands();
    console.log('', this.editObject)
  },
  methods: {
    ...mapActions([
      'teamByTeamNameId',
      'loadFreeUsersByRolePagination',
      'loadFreeTeamLeadersPagination',
      'loadFreePatronistPagination',
      'loadFreeHandSewersPagination',
      'loadAllFreeUsersByRolePagination',
    ]),

    async loadMore() {
      if (
        this.$refs.teamLeaderRef.offsetHeight + this.$refs.teamLeaderRef.scrollTop + 2
        >= this.$refs.teamLeaderRef.scrollHeight
      ) {
        await this.searchAuthors(this.teamLeaders.currentPage + 1);
      }
    },
    chands() {
      const sewers = [];
      for (let i = 0; i < this.editObject.sewerDtos.length; i++) {
        sewers.push(this.editObject.sewerDtos[i].sewerUserId);
      }
      this.editObject.sewerDtos = sewers;
      const patterns = [];
      for (let i = 0; i < this.editObject.patternMakerDtos.length; i++) {
        patterns.push(this.editObject.patternMakerDtos[i].patternMakerUserId);
      }
      this.editObject.patternMakerDtos = patterns;
      const handsewers = [];
      for (let i = 0; i < this.editObject.handSewerDtos.length; i++) {
        handsewers.push(this.editObject.handSewerDtos[i].handSewerUserId);
        this.editObject.handSewerDtos = handsewers;
      }
    },
    onSubmit() {
      // request body method from backend
      const object = {
        teamName: this.editObject.teamName,
        teamLeaderUserId: this.editObject.teamLeaderUserId,
        users: this.editObject.users.map((user) => {
          return {
            userId: user.userId,
            roleName: user.role,
          }
        }),
      };
      this.$emit('teamEdit', object);
      this.$refs.modal.hide();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onReset() {
      this.$refs.modal.hide();
      this.role = '';
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 10px;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
</style>
