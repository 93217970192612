<template>
  <div>
    <section class="main">
      <div
        class="flex"
        style="width: 100%"
      >
        <TeamsListing
          style="width:600px"
          @click="dressId"
        />
        <InsideTeam
          :material-id="dressIdShow"
          @click="colorId"
        />
      </div>

    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import InsideTeam from '@/components/teams/InsideTeam.vue'
import TeamsListing from '@/components/teams/TeamsListing.vue'
import { mapGetters } from 'vuex'


export default {
  name: 'Home',
  components: {
    TeamsListing,
    InsideTeam,
  },
  data() {
    return {
      dressIdShow: '',
      colorIdShow: '',
      productMatObj: {},
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  watch: {
    // eslint-disable-next-line func-names
    inside(item) {
      this.productMatObj = item
    },
  },
  methods: {
    inside(item) {
      this.productMatObj = item
    },
    dressId(id) {
      this.dressIdShow = id
    },
    colorId(id) {
      this.colorIdShow = id
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1440px){
  .main{
    padding: 75px 15px 15px 100px !important;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem !important;
  }

}
@media screen and (max-width: 673px) {
  .flex {
    flex-direction: column;
  }
}
</style>
