<template>
  <div>
    <b-modal
      id="modal-add-team"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SubmitYourTeam')"
      hide-footer
      @close="onReset"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('TeamName')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.teamName"
          />
        </b-form-group>

        <b-form-group :label="`${$t('TeamLeaderName')}:`">
          <vue-select
            id="teamLeaderName"
            v-model="$v.form.teamLeaderUserId.$model"
            :options="getFreeTeamLeaders"
            :reduce="(e) => e.userId"
            :label="$t('name')"
            :placeholder="$t('SelectTeamLeader')"
            :state="validateState('teamLeaderUserId')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <b-form-group :label="`${$t('Role')}:`">
          <vue-select
            id="role"
            v-model="role"
            :options="filteredOptions"
            label="text"
            :reduce="(e) => e.value"
            :placeholder="$t('SelectRole')"
            :state="validateState('sewerIds')"
            :clearable="false"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <b-form-group
          v-if="role != ''"
          :label="role.label"
        >
          <vue-select
            v-model="form.users"
            :options="getAllFreeUsers"
            :multiple="true"
            :label="$t('name')"
            :placeholder="$t('SelectEmployee')"
            :state="validateState('patternMakerIds')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <b-alert
          v-if="role != ''"
          variant="warning"
          show
        >
          {{ $t('KeepInMind') }} <br> {{ $t('ChangeRole') }}
        </b-alert>
        <b-form-group />
        <div class="buttonsEverywhere">
          <button
            type="button"
            class="buttonSubmit"
            variant="none"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"

            style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onReset"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  data() {
    return {
      role: '',
      user: [],
      teamLeadObj: [],
      showTeamLeaders: false,
      teamLeaders: {
        items: [],
      },
      form: {
        teamName: '',
        teamLeaderUserId: '',
        users: [],
      },
      filters: {
        page: 1,
        pageSize: 30,
        ...this.$route.query,
      },
      // getUserRole: [
      //   { value: 'Sewer', label: this.$t('Sewer') },
      //   { value: 'DiversPersonal', label: this.$t('DiversPersonal') },
      //   { value: 'Deliverer', label: this.$t('Deliverer') },
      //   { value: 'PatternMaker', label: this.$t('PatternMaker') },
      //   { value: 'Trainee', label: this.$t('Trainee') },
      //   { value: 'SalesPerson', label: this.$t('SalesPerson') },
      //   { value: 'Designer', label: this.$t('Designer') },
      //   { value: 'OnlineSales', label: this.$t('OnlineSales') },
      //   { value: 'HandSewer', label: this.$t('HandSewer') },
      //   { value: 'SalesPerson', label: this.$t('SalesPerson') },
      //   { value: 'Fitter', label: this.$t('Fitter') },
      //   { value: 'StockManagerMaterialVariant', label: this.$t('StockManagerMaterialVariant') },
      //   { value: 'StockManagerWarehousClient', label: this.$t('StockManagerWarehousClient') },
      //   { value: 'StockManagerWarehousProductions', label: this.$t('StockManagerWarehousProductions') },
      //   { value: 'Receptionist', label: this.$t('Receptionist') },
      //   { value: 'Accountant', label: this.$t('Accountant') },
      //   { value: 'ExecutiveAssitant', label: this.$t('ExecutiveAssitant') },
      //   { value: 'CleaningPerson', label: this.$t('CleaningPerson') },
      //   { value: 'Chauffeur', label: this.$t('Chauffeur') },
      //   { value: 'Security', label: this.$t('Security') },

      // ],
    };
  },
  validations: {
    form: {
      teamName: {
        required,
        // alpha,
        minLength: minLength(3),
      },
      teamLeaderUserId: {
        required,
      },
      sewerIds: {
        required,
      },
      patternMakerIds: {
        required,
      },
      handSewerIds: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(['getUserRole', 'getFreeUsers', 'getAllFreeUsers', 'getFreeTeamLeaders', 'getLanguage']),
    fullName() {
      return (
        this.teamLeadObj.firstName && `${this.teamLeadObj.firstName} ${this.teamLeadObj.lastName}`
      );
    },
    // roleOptions() {
    //   const excludedRoles = ['CEO', 'TeamLeader', 'Manager'];
    //   return this.getUserRole.filter((role) => { return !excludedRoles.includes(role) });
    // },
    filteredOptions() {
      const options = this.getUserRole.map((role) => {
        return {
          value: role,
          text: this.$t(role),
        }
      });
      return options;
    },
  },
  watch: {
    teamLeadObj() {
      this.test();
    },
    role(value) {
      this.loadAllFreeUsersByRolePagination({ personRole: value })
    },
  },
  mounted() {
    if (this.getUserRole.length == 0) {
      this.getUsersRole()
    }
  },
  methods: {
    ...mapActions([
      'getUsersRole',
      'loadFreeUsersByRolePagination',
      'loadAllFreeUsersByRolePagination',
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    test() {
      this.form.teamLeaderUserId = this.teamLeadObj.userId;
    },
    async loadMore() {
      if (
        this.$refs.teamLeaderRef.offsetHeight + this.$refs.teamLeaderRef.scrollTop + 2
        >= this.$refs.teamLeaderRef.scrollHeight
      ) {
        await this.searchAuthors(this.teamLeaders.currentPage + 1);
      }
    },
    onSubmit() {
      const modifiedForm = {
        teamName: this.form.teamName,
        teamLeaderUserId: this.form.teamLeaderUserId,
        users: this.form.users.map((user) => {
          return {
            userId: user.userId,
            roleName: user.role,
          }
        }),
      };
      this.$emit('teamAdd', modifiedForm);
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.form = {};
      this.role = ''
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}
</style>
