<template>
  <div
    class="mats-listing__wrapper"
  >
    <div style="display:flex;">
      <div style="margin-top: 15px; padding-right: 13px;">
        <button
          v-if="getLoggedInUser.role != 'TeamLeader' && getLoggedInUser.role != 'SalesPerson'"
          v-b-modal.modal-add-team
          class="button"
          @click="getFreeByRole"
        >
          <img
            src="../../assets/images/plus-circle.svg"
            alt=""
          >
          {{ $t('AddNewTeam') }}
        </button>
      </div>
      <div class="filtering table__search-input">
        <b-icon-search style="margin-top: 0px;" />
        <input
          v-model="searchTeam"
          style="margin-top: 5px;width: 100%;"
          type="text"
          :placeholder="$t('TeamName')"
        >
      </div>
    </div>
    <!-- <header class="list-header">
      <p>Team Names</p>
    </header> -->
    <div
      class="tt"
      style="margin-top: 13px;"
    >
      <!-- <hr class="mt-2 mb-3"> -->
      <div style="width: 100%; padding-top: 16px">

        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('TeamName') }}
              </th>
              <th>
                {{ $t('ActiveOrders') }}
              </th>
              <!-- <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left; color:#262E6C !important;">
                {{ $t('Edit') }}
              </th> -->
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="team in getFilteredTeams.length == 0 && searchTeam != '' ? getFilteredTeams : getFilteredTeams.length == 0 ? getTeamsNames : getFilteredTeams"
              :key="team.teamNameId"
              class="hover-row"
              :style="classNameCh === team.teamName ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
              style="border-bottom: 1px solid #dcdfe6;"
              @click="matProps(team)"
            >
              <td>
                {{ team.teamName }}
              </td>
              <td>
                {{ team.countItems == null ? 0 : team.countItems }}
              </td>
              <td
                v-if="getLoggedInUser.role != 'TeamLeader'"
                v-b-modal.modal-edit-team
                @click.stop="editTeamD(team)"
              >
                <b-icon-pencil />
              </td>
            </tr>
          </tbody>
          <!-- <tbody v-else>
            <tr>
              <td
                colspan="6"
                style="padding: 9px; text-align: left"
              >
                No Team was found!
              </td>
            </tr>
          </tbody> -->
        </table>
        <div>
          <b-pagination
            v-if="getTotalItemsForTeams > 15"
            v-model="page"
            :total-rows="getTotalItemsForTeams"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            size="md"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>

    <AddTeamsModal @teamAdd="teamData" />
    <EditTeamModal
      :edit-object="getTeamOverview"
      @teamEdit="editTeamData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import AddTeamsModal from '@/components/teams/modals/AddTeamsModal.vue';
import EditTeamModal from '@/components/teams/modals/EditTeamModal.vue';

export default {
  components: {
    AddTeamsModal,
    EditTeamModal,
  },
  data() {
    return {
      initialId: '',
      materialPropId: '',
      status: true,
      searchTeam: '',
      classNameCh: 'da',
      rows: [],
      materialObject: {},
      editTeamObject: {
        teamName: '',
        teamLeaderUserId: '',
        teamLeaderName: '',
        sewerIds: [],
        patternMakerIds: [],
        handSewerIds: [],
      },
      teamNameId: '',
      page: 1,
      pageSize: 15,
      columns: [
        {
          label: 'Team',
          field: 'team',
          tdClass: this.tdClass,
        },
        {
          label: 'Edit',
          field: 'edit',
          tdClass: this.tdClass,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getTeamsNames',
      'getCurrentPageForTeams',
      'getTotalItemsForTeams',
      'getTeamOverview',
      'getFilteredTeams',
      'getLoggedInUser',
    ]),
  },
  watch: {
    searchTeam(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (this.getFilteredTeams.length == 0 && value == '') {
          this.teamNames({
            pageNumber: this.getCurrentPageForTeams,
            pageSize: this.pageSize,
          })
          return;
        }
        setTimeout(() => {
          this.getTeamNameByNameLike(value)
        }, 250)
      }, 500)
    },
    page(value) {
      this.teamNames({
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    // filters: {
    //   // eslint-disable-next-line func-names
    //   deep: true,
    //   immediate: true,
    // },
    initialId() {
      this.immediateId(this.initialId);
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(['teamNames', 'teamByTeamNameId', 'addTeam', 'editTeam', 'getTeamNameByNameLike', 'changeLoadingtoTrue', 'loadFreeSewerPagination',
      'loadFreeTeamLeadersPagination',
      'loadFreePatronistPagination', 'loadFreeHandSewersPagination']),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.teamNames({
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    async teamData(obj) {
      this.addTeam({
        object: obj,
        successCallback: () => {
          this.teamNames({
            pageNumber: 1,
            pageSize: this.pageSize,
          });
        },
      });
    },
    async getFreeByRole() {
      try {
        await this.loadFreeTeamLeadersPagination({
          pageNumber: 1,
          pageSize: 10,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editTeamD(item) {
      this.getFreeByRole()
      await this.teamByTeamNameId(item.teamNameId);
      console.log(this.getTeamOverview)
      this.teamNameId = item.teamNameId;
      this.$bvModal.show('modal-edit-team');
    },
    async editTeamData(obj) {
      await this.editTeam({
        teamNameId: this.teamNameId,
        object: obj,
        successCallback: () => {
          this.teamNames({
            pageNumber: this.getCurrentPageForTeams,
            pageSize: this.pageSize,
          });
        },
      });
      this.editTeamObject.sewerIds = [];
    },

    immediateId(e) {
      this.$emit('click', e);
    },
    matProps(mat) {
      this.materialPropId = mat.teamNameId;
      this.$emit('click', mat);
      this.classNameCh = mat.teamName;
      this.teamByTeamNameId(mat.teamNameId);
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 16px 9px !important;
}

.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing {
  margin-top: 0;
}

.button {
  // width: 195px !important;
  padding-right: 15px;
}
tr:last-child {
  border-bottom: none !important;
}
.team_table {
  border-radius: 12px !important;
}
.justify-content-end{
  justify-content: flex-start !important;
}
table:hover {
  cursor: pointer;
}
// @media screen and (max-width: 1820px) {
//   .mats-listing__wrapper{
//   width: 390px !important;
//   }

// }


// .mat {
//   display: flex;
//   justify-content: space-between;
//   align-content: center;
//   &.active {
//     background-color: #e8e8e8;
//   }
//   div {
//     display: flex;
//     justify-content: space-between;
//     align-content: center;
//   }
//   span {
//     margin-left: 10px;
//     svg {
//       font-size: 15px;
//     }
//   }
// }
@media screen and (max-width: 673px) {
  .tt {
    width: auto !important;
  }
}
</style>
