<template>
  <div>
    <b-modal
      id="edit-feedback"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('Editreview')} ${nameOfEmployee} - ${year} ${monthTranslated}`"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <div>
        <b-form-group
          id="input-group-8"
          :label="$t('Rating')"
          style="width:100%"
          label-for="input-25"
        >
          <b-form-rating
            v-model="rangingType"
            variant="warning"
            class="mb-2"
            max="4"
          />
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="input-group-8"
          :label="$t('Feedback')"
          style="width:100%"
          label-for="input-25"
        >
          <b-form-textarea
            id="textarea"
            v-model="notes"
            :placeholder="$t('WriteHere')"
            rows="3"
            max-rows="3"
          />
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>


export default {
  props: {
    nameOfEmployee: {
      type: String,
      default: '',
    },
    feebackObject: {
      type: Object,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    year: {
      type: Number,
    },
    monthTranslated: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      notes: null,
      rangingType: null,
    }
  },
  watch: {
    'feebackObject.notes': {
      handler(value) {
        this.notes = value
      },
    },
    'feebackObject.rangingType': {
      handler(value) {
        this.rangingType = value
      },
    },
    notes(value) {
      if (value == null) {
        this.notes = this.feebackObject.notes
      } else {
        this.notes = value
      }
    },
    rangingType(value) {
      if (value == null) {
        this.rangingType = this.feebackObject.rangingType
      } else {
        this.rangingType = value
      }
    },
  },
  methods: {
    onSubmit() {
      const objekti = {
        notes: this.notes,
        rangingType: this.rangingType,
        month: this.feebackObject.month,
        year: this.feebackObject.year,
      }
      this.$emit('editFeedback', objekti);
      this.$refs.modal.hide()
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
    },
    onReset() {
      this.notes = null
      this.rangingType = null
    },
  },
}
</script>
