<template>
  <div>
    <b-sidebar
      id="employee-worklog"
      :title="`${$t('EmployeeTrackAndTrace')} of ${nameOfEmployee}`"
      right
      header-class="headerModalToggle"
      shadow
      no-close-on-esc
      width="50%"
    >
      <template #title>
        <strong style="color: #101828;">{{ `${$t('EmployeeTrackAndTrace')} of ${nameOfEmployee}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div class="px-2 py-2">
        <div style="display: flex;gap: 10px;">
          <b-form-group>
            <vue-select
              v-model="year"
              :options="yearsArrays"
              style="width: 200px;"
            />
          </b-form-group>
          <b-form-group>
            <vue-select
              v-model="selectedMonth"
              :options="monthOptions"
              label="text"
              :reduce="(e) => e.value"
              style="width: 200px;"
            />
          </b-form-group>
        </div>
        <div style="width: 100%;margin-top: 10px;  ">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('OrderNumber') }}
                </th>
                <th>
                  {{ $t('ArticleName') }}
                </th>
                <th>
                  {{ $t('State') }}
                </th>
                <th>
                  {{ $t('Time') }}
                </th>
                <th>
                  {{ $t('ExecutorName') }}
                </th>
                <th>
                  {{ $t("ProcessedBy") }}
                </th>
                <th>
                  {{ $t('Message') }}
                </th>
              </tr>
            </thead>
            <tbody
              v-if="getTrackAndTraceByEmployee.length > 0"
            >

              <tr
                v-for="order in getTrackAndTraceByEmployee"
                :key="order.id"
              >
                <td>
                  {{ order.orderNumber }}
                </td>
                <td>
                  {{ order.articleNumber }}
                </td>
                <td>
                  {{ $t(order.state) }}
                </td>
                <td>
                  {{ convertUTCDateToLoacalDate(order.created) }}

                </td>
                <td>
                  {{ order.executorName }}
                </td>
                <td>
                  {{ order.processingUserName }}
                </td>
                <td>
                  {{ order.message }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  colspan="5"
                  style="padding: 9px; text-align: left;"
                >
                  {{ $t('Userfeedback') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="px-2 py-2">
        <button
          v-b-modal.add-feedback
          class="button"
        >
          <b-icon-plus />
          {{ $t("AddReview") }}
        </button>
        <div style="width: 100%;margin-top: 10px;  ">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('Month') }}
                </th>
                <th>
                  {{ $t('Rating') }}
                </th>
                <th>
                  {{ $t('ExecutorName') }}
                </th>
                <th>
                  {{ $t('Notes') }}
                </th>
                <th>
                  {{ $t('Edit') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user,order) in getFeedback"
                :key="order.feedbackId"
              >
                <td>
                  {{ monthToString(user.month) }}

                </td>
                <td style="display: flex; justify-content: flex-start;">
                  <b-form>
                    <b-form-rating
                      v-model="user.rangingType"
                      variant="warning"
                      class="mb-2"
                      readonly
                    />
                  </b-form>
                </td>
                <td>
                  {{ user.executorName }}
                </td>
                <td>
                  {{ user.notes }}
                </td>
                <td
                  v-b-modal.edit-feedback
                  style="cursor: pointer;"
                  @click="sentFeedback(user)"
                >
                  <b-icon-pencil />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <TeamLeaderFeedback
        :year="year"
        :existing-array="existingArray"
        :selected-month="selectedMonth"
        :user-id-of-employee="userIdOfEmployee"
        :name-of-employee="nameOfEmployee"
        @addFeedback="addFeedbackTeamLeader"
      />
      <EditTeamLeaderFeedback
        :name-of-employee="nameOfEmployee"
        :year="year"
        :month-translated="monthTranslated"
        :feeback-object="feebackObject"
        @editFeedback="editFeedbackTeamLeader"
      />
    </b-sidebar>
  </div>
</template>

<script>
import moment from 'moment';
import TeamLeaderFeedback from '@/components/teams/modals/TeamLeaderFeedback.vue'
import EditTeamLeaderFeedback from '@/components/teams/modals/EditTeamLeaderFeedback.vue'

import { mapActions, mapGetters } from 'vuex';


export default {
  components: {
    TeamLeaderFeedback,
    EditTeamLeaderFeedback,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    year: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    month: {
      type: Number,
    },
    nameOfEmployee: {
      type: String,
      default: '',
    },
    userIdOfEmployee: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      monthTranslated: null,
      feedbackId: null,
      feebackObject: null,
      existingArray: [],
      selectedMonth: this.month.toString(),
      userObj: {},
      showOtherComponent: false,
      isactive: true,
      searchUser: '',
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      editUser: {
        userId: '',
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        country: '',
        email: '',
        role: '',
        resetPassword: false,
      },
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getEmployeeFeedbackPagination',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
      'getLanguage',
      'getTrackAndTraceByEmployee',
      'getFeedback',
    ]),
    monthOptions() {
      const months = [
        { text: this.$t('January'), value: '1' },
        { text: this.$t('February'), value: '2' },
        { text: this.$t('March'), value: '3' },
        { text: this.$t('April'), value: '4' },
        { text: this.$t('May'), value: '5' },
        { text: this.$t('June'), value: '6' },
        { text: this.$t('July'), value: '7' },
        { text: this.$t('August'), value: '8' },
        { text: this.$t('September'), value: '9' },
        { text: this.$t('October'), value: '10' },
        { text: this.$t('November'), value: '11' },
        { text: this.$t('December'), value: '12' },
      ];

      return months.map((month) => {
        return {
          text: this.month === parseInt(month.value, this.month) ? this.getMonthText(month.value) : month.text,
          value: month.value,
        }
      });
    },
    yearsArrays() {
      const result = [];
      const baseYear = this.year || moment().year();

      for (let i = -3; i < 4; i++) {
        result.push(baseYear + i);
      }

      return result;
    },
    filteredUsers() {
      // filter the users array to only show rows with the same id as the selected id
      return this.selectedId
        ? this.getEmployeeFeedbackPagination.filter((getEmployeeFeedbackPagination) => {
          return getEmployeeFeedbackPagination.userId === this.selectedId;
        })
        : this.getEmployeeFeedbackPagination;
    },
  },
  watch: {
    getFeedback(value) {
      const array = value.map((item) => { return item.month })
      this.existingArray = array
    },
    selectedMonth(value) {
      this.loadTrackAndTraceByEmployee({
        processingUserId: this.userIdOfEmployee,
        year: this.year,
        month: value,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    year(value) {
      this.loadFeedback({
        userId: this.userIdOfEmployee,
        year: value,
      })
      this.loadTrackAndTraceByEmployee({
        processingUserId: this.userIdOfEmployee,
        year: value,
        month: this.selectedMonth,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    page(value) {
      if (this.isactive) {
        this.loadEmployeeFeedbackPagination({
          firstName: this.searchUser,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      }
    },
    searchUser(value) {
      this.selectedId = null;
      if (value == '') {
        this.removeLists();
        if (this.isactive) {
          this.loadEmployeeFeedbackPagination({
            firstName: value,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: value,
          });
        }
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else if (this.isactive) {
        this.filterActiveUsersByName(value);
      } else {
        this.filterInactiveUsersByName(value);
      }
      this.noSearch = true;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions([
      'loadEmployeeFeedbackPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'loadFeedback',
      'loadEmployeeFeedbackPagination',
      'add_feedback',
      'loadTrackAndTraceByEmployee',
      'loadFeedback',
      'edit_feedback',
    ]),
    sentFeedback(value) {
      this.monthTranslated = this.monthToString(value.month)
      this.feedbackId = value.feedbackId
      this.feebackObject = value
    },
    addFeedbackTeamLeader(obj) {
      this.add_feedback({
        object: obj,
        successCallback: () => {
          this.loadFeedback({
            userId: this.userIdOfEmployee,
            year: this.year,
          })
          this.loadTrackAndTraceByEmployee({
            processingUserId: this.userIdOfEmployee,
            year: this.year,
            month: this.selectedMonth,
            pageNumber: 1,
            pageSize: 15,
          })
        },
      })
    },
    editFeedbackTeamLeader(obj) {
      this.edit_feedback({
        feedbackId: this.feedbackId,
        object: obj,
        successCallback: () => {
          this.loadFeedback({
            userId: this.userIdOfEmployee,
            year: this.year,
          })
          this.loadTrackAndTraceByEmployee({
            processingUserId: this.userIdOfEmployee,
            year: this.year,
            month: this.selectedMonth,
            pageNumber: 1,
            pageSize: 15,
          })
        },
      })
    },
    getMonthText(value) {
      const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December',
      ];

      return months[value];
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    monthToString(value) {
      if (value == 1) {
        return this.$t('January')
      } if (value == 2) {
        return this.$t('February')
      } if (value == 3) {
        return this.$t('March')
      } if (value == 4) {
        return this.$t('April')
      } if (value == 5) {
        return this.$t('May')
      } if (value == 6) {
        return this.$t('June')
      } if (value == 7) {
        return this.$t('July')
      } if (value == 8) {
        return this.$t('August')
      } if (value == 9) {
        return this.$t('September')
      } if (value == 10) {
        return this.$t('October')
      } if (value == 11) {
        return this.$t('November')
      } if (value == 12) {
        return this.$t('December')
      }
      return ''
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);

        await this.loadEmployeeFeedbackPagination({
          firstName: null,
          lastName: null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    matProps(users) {
      this.$emit('click', users);
      this.classNameCh = users.userId;
      this.$emit('show-the-component');
    },
    loadRemainingDays(value) {
      const currentYear = new Date().getFullYear();
      this.remainingDaysOfEmployee({ employeeUserId: value, year: currentYear });
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.loadEmployeeFeedbackPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    showSameId(id) {
      // set the selected id to the id of the clicked row
      this.selectedId = id;
    },
    loadFeedbackk(id) {
      // console.log('testt', id.userId)
      const currentYear = new Date().getFullYear();
      this.loadFeedback({ userId: id.userId, year: currentYear })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },

    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      // console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    sortByRole() {
      if (this.sortedR == false) {
        this.getEmployeeFeedbackPagination.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedR = true;
      } else {
        this.getEmployeeFeedbackPagination.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedR = false;
      }
    },
    sortByRoleI() {
      if (this.sortedRI == false) {
        this.getInactiveUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedRI = true;
      } else {
        this.getInactiveUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedRI = false;
      }
    },
    nameToShow(value) {
      if (value > 0) {
        return value
      }
      return '0'
    },
    async searchByOn(value) {
      const fullName = value.fullName;
      const spaceIndex = fullName.indexOf(' ');
      let firstName; let
        lastName;

      if (spaceIndex !== -1) {
        // Split the fullName into firstName and lastName
        firstName = fullName.slice(0, spaceIndex);
        lastName = fullName.slice(spaceIndex + 1);
      } else {
        // If there is no space, assume the entire name is the firstName
        firstName = fullName;
        lastName = ''; // You can set this to an empty string or handle it differently
      }

      this.searchUser = fullName;
      this.noSearch = false;
      await this.loadEmployeeFeedbackPagination({
        firstName,
        lastName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>

.team_table td {
  padding: 16px 10px;
}

.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
    margin-right: auto;
    border: 0 !important;
    background: white;
}
/* .button {
  width: 195px;
} */

/* .viewAllBtn{
  background: #FF274F;
}
.addEmployeeButton {
  width: 210px;
} */

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
