<template>
  <div>
    <b-modal
      id="add-feedback"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AddFeedback')} ${nameOfEmployee} `"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <div style="display: flex;gap: 10px;">
        <b-form-group style="width: 100%;">
          <vue-select
            v-model="yearFeedback"
            disabled
          />
        </b-form-group>
        <b-form-group style="width: 100%;">
          <vue-select
            v-model="selectedMonthFeedback"
            :options="monthOptions"
            :placeholder="$t('SelectMonth')"
            label="name"
            :reduce="(e) => e.value"
          />
        </b-form-group>
      </div>
      <div style="margin-top: 10px;">
        <b-form-rating
          v-model="rangingType"
          variant="warning"
          class="mb-2"
          max="4"
        />
      </div>
      <div>
        <b-form-group
          id="input-group-8"
          :label="$t('Feedback')"
          style="width:100%"
          label-for="input-25"
        >
          <b-form-textarea
            id="textarea"
            v-model="notes"
            :placeholder="$t('WriteHere')"
            rows="3"
            max-rows="3"
          />
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex'


export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    year: {
      type: Number,
    },
    selectedMonth: {
      type: String,
      default: '',
    },
    userIdOfEmployee: {
      type: String,
      default: '',
    },
    nameOfEmployee: {
      type: String,
      default: '',
    },
    existingArray: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selectedMonthFeedback: null,
      yearFeedback: moment().year(),
      rangingType: null,
      notes: null,
      months: [
        {
          value: 1,
          name: this.$t('January'),
        },
        {
          value: 2,
          name: this.$t('February'),
        },
        {
          value: 3,
          name: this.$t('March'),
        },
        {
          value: 4,
          name: this.$t('April'),
        },
        {
          value: 5,
          name: this.$t('May'),
        },
        {
          value: 6,
          name: this.$t('June'),
        },
        {
          value: 7,
          name: this.$t('July'),
        },
        {
          value: 8,
          name: this.$t('August'),
        },
        {
          value: 9,
          name: this.$t('September'),
        },
        {
          value: 10,
          name: this.$t('October'),
        },
        {
          value: 11,
          name: this.$t('November'),
        },
        {
          value: 12,
          name: this.$t('December'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getTrackAndTraceByEmployee',
      'getFeedback',
    ]),
    monthOptions() {
      return this.months.filter((month) => { return !this.existingArray.includes(month.value) });
    },
  },
  watch: {
    year(value) {
      this.yearFeedback = value
    },
  },
  methods: {
    ...mapActions([
      'loadTrackAndTraceByEmployee',
      'loadFeedback',
    ]),
    getMonthText(value) {
      const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December',
      ];

      return months[value];
    },
    onSubmit() {
      const objekti = {
        userId: this.userIdOfEmployee,
        notes: this.notes,
        rangingType: this.rangingType,
        month: this.selectedMonthFeedback,
        year: this.yearFeedback,
      }
      this.$emit('addFeedback', objekti);
      this.$refs.modal.hide()
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.notes = null;
      this.rangingType = null
      this.selectedMonthFeedback = null
    },
  },
}
</script>
